(function () {
    'use strict';

    angular
        .module('sfzSintegraGatewayApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$uibModal', 'Principal', 'LoginService', '$state', 'ContribuinteService', '$filter'];

    function HomeController($scope, $uibModal,Principal, LoginService, $state, ContribuinteService, $filter) {
        var vm = this;
        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        vm.cancel = cancel;
        vm.imprimir = imprimir;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.cnpj = "";
        vm.caceal = "";
        vm.error = null;
        vm.isSafari = false;
        vm.contribuintes = null;
        vm.contribuinteSelecionado = null;
        vm.listaDeObservacoes = [];

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        function cancel () {
            vm.contribuinteSelecionado = null;
            modalInstance.dismiss('cancel');
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        function clearErros() {
            vm.error = false;
            vm.mensagensErro = null;
        }

        function imprimir() {
            var parametros = {
                numeroCaceal: vm.contribuinte.caceal,
                cnpj: vm.cnpj
            };
            ContribuinteService.imprimirContribuinte(parametros, falhaRelatorio);
        }

        function downloadPdf(response) {
            // saveAs(response.body, response.headers.get('x-filename'));
            var file = new Blob([response.body], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }

        function falhaRelatorio(result) {
            console.log(result);
        }

        vm.consultarContribuintes = function (callback) {
            clearErros();
            var contribuinte = {
                caceal: vm.caceal,
                cnpj: vm.cnpj
            };
            ContribuinteService.consultar(contribuinte, onSuccess, onFailure);
            vm.contribuintes = null;
            vm.listaDeObservacoes = [];

            function onSuccess(result) {
                vm.contribuintes = result.data;
            }
        };

        vm.detalharContribuinte = function detalhar(contribuinte) {
            vm.listaDeObservacoes = [];
            vm.contribuinte = contribuinte;
            obterInformacoesObservacoes();

            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/contribuinte/contribuinteDetalhes.html',
                controllerAs: 'vm',
                size: 'lg',
                scope: $scope,
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }

            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );
        };

        function obterInformacoesObservacoes() {
            var mensagemSituacaoCadastral = "Situação Cadastral: " + vm.contribuinte.descricaoSituacaoCadastral;
            vm.listaDeObservacoes.push(mensagemSituacaoCadastral);

            var mensagemOpcaoSimples = obterInformacaoOpcaoSimples(vm.contribuinte.indicadorOpcaoSimples);
            vm.listaDeObservacoes.push(mensagemOpcaoSimples);

            var mensagemOpcaoMei = obterInformacaoMei(vm.contribuinte.indicadorOpcaoSimples, vm.contribuinte.indicadorMei);
            if (mensagemOpcaoMei != "") {
                vm.listaDeObservacoes.push(mensagemOpcaoMei);
            }

            var mensagemContribuinteObrigado = obterInformacaoContribuinteObrigado(vm.contribuinte.contribuinteObrigado,
                                                                               vm.contribuinte.dataObrigatoriedade);
            if (mensagemContribuinteObrigado != "") {
                vm.listaDeObservacoes.push(mensagemContribuinteObrigado);
            }

            var mensagemContribuinteRestricao = obterInformacaoContribuinteRestricao(vm.contribuinte.contribuinteRestricao);
            if(mensagemContribuinteRestricao != "") {
                vm.listaDeObservacoes.push(mensagemContribuinteRestricao);
            }
        };

        function obterInformacaoOpcaoSimples(indicadorOpcaoSimples) {
            if (indicadorOpcaoSimples == "S") {
                return "Optante pelo Simples Nacional";
            }
            return "Não optante pelo Simples Nacional";
        };

        function obterInformacaoMei(indicadorOpcaoSimples, indicadorMei) {
            if (indicadorOpcaoSimples == "S" && indicadorMei == "S") {
                return "Optante pelo MEI";
            }
            return "";
        };

        function obterInformacaoContribuinteObrigado(ehObrigado, dataInicio) {
            if (ehObrigado != null) {
                var mensagemContribuinteObrigado = "Início da obrigatoriedade ao SPED-EFD: ";
                var dataInicioObrigatoriedadeSPED = $filter('date')(dataInicio, 'MM/yyyy');

                return mensagemContribuinteObrigado + dataInicioObrigatoriedadeSPED;
            }
            return "";
        };

        function obterInformacaoContribuinteRestricao(contribuinteRestricao) {
            if (contribuinteRestricao) {
                return "Tem restrição (Lei 6474/2004)";
            }

            return "";
        };

        function onFailure(result) {
            vm.error = true;
            vm.mensagensErro = [];
            vm.mensagensErro.push(result.data);
        }
    }
})();
