(function() {
    'use strict';

    var URL = "sfz-sintegra-api/api/consultar/contribuinte";
    var URL_REL = "sfz-sintegra-api/api/relatorio";

    angular
        .module('sfzSintegraGatewayApp')
        .factory('ContribuinteService', ContribuinteService);

    ContribuinteService.$inject = ['$http', '$uibModal', 'DataUtils'];

    function ContribuinteService ($http, $uibModal, DataUtils) {

        function ContribuinteService() {
        }

        ContribuinteService.consultar = function(contribuinte, noSucesso, naFalha) {
            $http.post(URL, contribuinte).then(noSucesso, naFalha);
        }

        ContribuinteService.imprimirContribuinte = function(parametrosRelatorio, onFail) {
            // downloadRelatorio(URL_REL + "/imprimirContribuinte", parametrosRelatorio, onFail);
            $http({
                method: 'POST',
                url: URL_REL + "/imprimirContribuinte",
                data: JSON.stringify(parametrosRelatorio),
                responseType: 'arraybuffer'
            }).then(DataUtils.downloadFileAsPdf, onFail);
        }

        return ContribuinteService;
    }
})();
