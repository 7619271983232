(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('sfzSintegraGatewayApp')
        .constant('VERSION', "2.0.1.201912181448")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1576692186670)
;
})();
